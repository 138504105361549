// src/redux/store.js
import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import reducer from "./Reducer/index";

export const store = createStore(
    reducer,
    applyMiddleware(thunk),
  );
  
//   import {createStore, applyMiddleware} from "redux";
//   import {thunk} from "redux-thunk";
//   import {composeWithDevTools} from "@redux-devtools/extension";
//   import {persistStore, persistReducer} from "redux-persist";
//   import storage from "redux-persist/lib/storage";
//   import reducer from "./Reducer/index";


// const persistConfig = {
//     key: "root",
//     storage,
//     // blacklist: ['getAllUsers'],
// };

// const persistedReducer = persistReducer(persistConfig, reducer);

// const store = createStore(
//     persistedReducer,
//     composeWithDevTools(applyMiddleware(...[thunk]))
// )

// const persistor = persistStore(store);

// export {store, persistor};