import React, { useState } from "react";
import "./Input.css";
import HidePassword from "../../assets/password.png";
import ShowPassword from "../../assets/show-password.png";

const Input = ({ label, type, placeholder, value, onChange }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const getInputType = () => {
    if (type === "password") {
      return passwordVisible ? "text" : "password";
    }
    return type;
  };
  return (
    <div className="form-input">
      <label
        style={{
          flexDirection: type === "checkbox" ? "row-reverse" : null,
          gap: type === "checkbox" ? "2px" : null,
          
        }}
      >
        {label}
        <div className="password-input-container">
          <input
            type={getInputType()}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            style={{
              width: type === "checkbox" ? "50px" : null,
              height: type === "checkbox" ? "15px" : null,
              color: type === "checkbox" ? "#D11C1C": null
            }}
          />
          {type === "password" && (
            <button
              type="button"
              className="password-toggle-button"
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? (
                <img src={HidePassword} width={20} height={15} />
              ) : (
                <img
                  src={ShowPassword}
                  width={20}
                  height={15}
                  style={{ color: "white" }}
                />
              )}
            </button>
          )}
        </div>
      </label>
    </div>
  );
};

export default Input;
