import ApiCaller from "../../ApiHeader/ApiCaller";
import HekActions from "../Action/HEKAction";

const token = localStorage.getItem("token");
const bearerHeaders = ApiCaller.BearerHeaders(JSON.parse(token));

export default class HeksMiddleware {
  static getHeks() {
    return async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiCaller.Get("get-all-heks", bearerHeaders);
          // https://enigmakey.tech/serv/get-all-heks-not_associated
          if (response.status == 201 || response.status == 200) {
            resolve(response.data);
            dispatch(HekActions.getAllHeks(response.data));
          }
        } catch (error) {
          console.error("Error fetching Heks:", error);
        }
      });
    };
  }
  static availableHeks() {
    return async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiCaller.Get("get-all-heks-not_associated", bearerHeaders);
          // https://enigmakey.tech/serv/get-all-heks-not_associated
          if (response.status == 201 || response.status == 200) {
            resolve(response.data);
            dispatch(HekActions.getAvailableHeks(response.data));
          }
        } catch (error) {
          console.error("Error fetching Heks:", error);
        }
      });
    };
  }
  static getHeksWithIp() {
    return async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiCaller.Get(
            "get-all-heks-with-ip",
            bearerHeaders
          );
          if (response.status ==201 || response.status == 200) {
            resolve(response.data);
            dispatch(HekActions.getHekWithIP(response.data));
          } 
        } catch (error) {
          console.error("Error fetching Heks:", error);
        }
      });
    };
  }

}
