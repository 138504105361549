import ApiCaller from "../../ApiHeader/ApiCaller";
import AuthAction from "../Action/AuthAction";

const token = localStorage.getItem("token");
const bearerHeaders = ApiCaller.BearerHeaders(JSON.parse(token));

const getBearerHeaders = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found in local storage.");
  }
  return ApiCaller.BearerHeaders(token); // Assuming BearerHeaders takes a string token, not an object
};

export default class AuthMiddleware {
  static SignIn({ email, password }) {
    return async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiCaller.Post("login-post", {
            email,
            password,
          });
          if (response.status == 201 || response.status == 200) {
            resolve(response.data);
            dispatch(AuthAction.Signin(response.data));
          }
        } catch (error) {
          alert('Email or password is wrong or server error!');
          console.error(error.message);
        }
      });
    };
  }
  static getAllUsers() {
    return async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        try {
          // const navigate = useNavigate();
          const response = await ApiCaller.Get("get-all-users", bearerHeaders);
          if (response.status == 201 || response.status == 200) {
            resolve(response.data);
            dispatch(AuthAction.allUsers(response.data));
          } 
          // else if(response?.status === 401){
          //    localStorage.removeItem("userData");
          //    localStorage.removeItem("tokrn");
          //   //  navigate('login)
          // }
        } catch (error) {
          console.error("Error fetching Heks:", error);
        }
      });
    };
  }
  static getAllUsersWithIp() {
    return async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiCaller.Get(
            "get-all-users-with-ip",
            bearerHeaders
          );
          if (response.status == 201 || response.status == 200) {
            resolve(response.data);
            dispatch(AuthAction.allUsersWithIp(response.data));
          }
        } catch (error) {
          console.error("Error fetching Heks:", error);
        }
      });
    };
  };

  static editUser(userId, userData) {
    return async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiCaller.Put(`edit-user/${userId}`, userData, bearerHeaders);
          if (response.status === 200) {
            resolve(response.data);
            dispatch(AuthAction.editUser(response.data));
          } else {
            reject(response);
          }
        } catch (error) {
          console.error("Error editing user:", error);
          reject(error);
        }
      });
    };
  }
  
  static deleteUser(userId) {
    return async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiCaller.Delete(`delete-user/${userId}`, getBearerHeaders());
          if (response.status == 200 || response.status == 204) {
            resolve(response.data);
            dispatch(AuthAction.deleteUser(userId));
          } else {
            reject(new Error("Failed to delete user"));
          }
        } catch (error) {
          console.error("Error deleting user:", error);
          reject(error);
        }
      });
    };
  };
}
