import React, {useState, useEffect, useCallback} from "react";
import "./HekTable.css";
import Refresh from "../../assets/refresh.png";
import Dots from "../../assets/dots.png";
import Arrdown from "../../assets/Arrowdown.png";
import Union from "../../assets/Union.png";
import Editing from "../../assets/Editing.png";
import Bin from "../../assets/Bin.png";
import Search from "../../assets/search.png";
import Setting from "../../assets/Settings.png";
import UserDetailCard from "../UserDetailCard/UserDetailCard";
import debounce from 'lodash.debounce';
import NoDataFound from '../../assets/data_not_found.png';
import { CSVLink } from "react-csv";
import CSV from '../../assets/csv.png';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Oval } from 'react-loader-spinner';

const HekTable = ({ data, onShow, showHek, onDelete, onEdit }) => {
  const [showSelectedHEKData, setShowSelectedHEKData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [selectedHEK, setSelectedHEK] = useState(null);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
  
  }, [filteredData]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEditClick = (user) => {
    onEdit(user);
    setShowModal(true);
  };

  const handleDeleteClick = (user) => {
    setSelectedHEK(user);
    setShow(true);
  };

  const handleDeleteConfirm = () => {
    onDelete(selectedHEK.id);
    setShow(false);
  };

  const handleClose = () => setShow(false);
  
  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query) {
        const lowerCaseQuery = query.toLowerCase();
        const filtered = data?.filter((item) =>
          Object.values(item).some(
            (val) =>
              val &&
              val.toString().toLowerCase().includes(lowerCaseQuery)
          )
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(data);
      }
    }, 300),
    [data]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch]);

  const windowRefresh = () => {
    window.location.reload()
  }

  
  const selectHEK = (user) => {
    setShowSelectedHEKData(user)
  }



  const headers = [
    { label: "ID", key: "id" },
    { label: "Serial Number", key: "Serial_Number" },
    { label: "Client Public Key", key: "client_public_key" },
    { label: "Connection", key: "Connection" },
    { label: "Status", key: "Status" },
    { label: "Client IP", key: "client_ip" },
  ];

  return (
    <div className="tableContainer">
      <div className="headerContainer">
        <div className="headerTitle">
          <h2 style={{ fontWeight: "bolder" }}>HEKs</h2>
        </div>
        <div className="headerTitle">
          <button className="iconbutton" onClick={windowRefresh}>
            <img src={Refresh} width={40} height={35} />
          </button>
          {data?.length > 0 && (
            <CSVLink
              data={data}
              headers={headers}
              filename={"heks-data.csv"}
              style={{ fontSize: 12, color: 'gray' }}
              onClick={event => {
                alert('CSV is downloading...');
              }}
            >
              <img src={CSV} width={30} />
            </CSVLink>
          )}
          {/* <button className="iconbutton">
            <img src={Dots} width={30} height={30} />
          </button> */}
        </div>
      </div>
      {filteredData === undefined && !filteredData ? 
       <div>
       {isLoading ? (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', margin: '100px auto' }}>
         <Oval
           visible={true}
           height="80"
           width="80"
           color="black"
           secondaryColor="red"
           ariaLabel="oval-loading"
           wrapperStyle={{}}
           wrapperClass=""
         />
         </div>
       ) : (
         <>
           <div style={{ display: 'flex', justifyContent: 'center' }}>
             <img src={NoDataFound} alt="no_data_found" />
           </div>
           <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
             <h5> No data found try to reload the browser </h5>
             <button className="iconbutton" onClick={windowRefresh}>
               <img src={Refresh} width={40} height={35} />
             </button>
           </div>
         </>
       )}

     </div>
      : 
      <>
        <div className="filterContainer">
        <div className="headerTitle">
          <div className="search-box">
            <input type="text" placeholder="Search"  value={searchTerm}
              onChange={handleSearch} />
            <button className="search-icon">
              <img src={Search} width={18} height={16} />
            </button>
          </div>
          {/* <button className="iconbutton">
            <img src={Union} width={16} height={16} />
          </button>
          <button className="filterButton">Filter</button> */}
        </div>
        <div className="button-pair">
          <button className="modalButton" onClick={showHek}>Add New HEK</button>
          <button className="modalButton" onClick={onShow}>Associate HEK to User</button>
        </div>
      </div>
      <div className="tableScrollable" style={{maxHeight: showSelectedHEKData ? '40vh' : '100vh'}}>
        <table style={{ width: "100%", margin: "13px", overflow: "auto", boxSizing: 'border-box',   }}>
          <thead>
            <tr>
              {/* <th></th> */}
              <th>ID</th>
              <th>Serial Number </th>
              <th>Status</th>
              <th>Access</th>
              <th>Connection</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((item) => (
              <tr key={item?.id}>
                {/* <td>
                  <input type="checkbox" />
                </td> */}
                <td><button className="iconbutton" onClick={() => selectHEK(item)}>
                  <input type="radio" name="select" />
                  </button> {item?.id}</td>
                <td>{item?.Serial_Number ? item?.Serial_Number : "-"}</td>
                <td>{item?.Status}</td>
                <td>{item?.Access}</td>
                <td>{item?.Connection}</td>
                <td>
                  <button className="iconbutton" onClick={() => handleEditClick(item)}>
                    <img src={Editing} />
                  </button>
                  <button className="iconbutton" onClick={() => handleDeleteClick(item)}>
                    <img src={Bin} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </>
}
    
      {showSelectedHEKData ? <UserDetailCard showSelectedHEKData={showSelectedHEKData} setShowSelectedHEKData={setShowSelectedHEKData} CardHeading={"HEK Details"} /> : null}

      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>Do you want to delete {selectedHEK?.Serial_Number}?</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleDeleteConfirm}>
              Delete
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default HekTable;
