import { VPN_CONFIG_GENERATE } from "../action_type";

export default class VPNConfig {
  static Generate_Config(data) {
    return {
      type: VPN_CONFIG_GENERATE,
      payload: data
    };
  }
}
