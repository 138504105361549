import React, { useState } from 'react';
import './Checkbox.css'; // Assuming you have a CSS file named Checkbox.css

function Checkbox() {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <div className="checkbox-container">
      <input 
        type="checkbox" 
        id="rememberMe" 
        checked={checked} 
        onChange={handleCheckboxChange} 
      />
      <label htmlFor="rememberMe">
        <span>Remember me</span>
      </label>
    </div>
  );
}

export default Checkbox;
