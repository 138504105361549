import React, { useEffect } from "react";
import VPNConnection from "../../Component/VPNConnection/VPNConnection";
import VPNTable from "../../Component/VPNProfileTable/VPNProfileTable";
import { useDispatch, useSelector } from "react-redux";
import HeksMiddleware from "../../Redux/Middleware/HekMiddleware";
import AuthMiddleware from "../../Redux/Middleware/AuthMiddleware";

const NetworkConnectivity = () => {
  const {HekwithIps} = useSelector((state) => state.heks);
  const {userWithIps} = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    // Implement the delete logic here
    // Dispatch an action to delete the user
    // dispatch(AuthMiddleware.deleteUser(userId));
  };


  const token = localStorage.getItem("userData");

  useEffect(() => {
    dispatch(HeksMiddleware.getHeksWithIp());
    dispatch(AuthMiddleware.getAllUsersWithIp());
  }, [dispatch, token]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <VPNTable TableHeader={"Manage User VPN Profile"} data={userWithIps} onDelete={handleDelete} />
        {/* <VPNTable TableHeader={"Manage HEK VPN Profile"}  data={HekwithIps}  onDelete={handleDelete} /> */}
      </div>
      <VPNConnection />
    </div>
  );
};

export default NetworkConnectivity;
