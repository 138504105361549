import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import Header from "./Component/Header/Header";
import Sidebar from "./Component/Sidebar/Sidebar";
import UserManagement from "./Pages/UserManagement/UserManagement";
import Dashboard from "./Pages/Dashboard/Dashboard";
import HekDeviceManagement from "./Pages/HekDeviceManagement/HekDeviceManagement";
import LedgerEvent from "./Pages/LedgerEvent/LedgerEvent";
import Support from "./Pages/Support/Support";
import NetworkConnectivity from "./Pages/Network/NetworkConnectivity";
import Login from "./Pages/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import { SIGNIN } from "./Redux/action_type";
import { useEffect } from "react";
import Permission from "./Pages/Permission/Permission";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const userData = localStorage.getItem("userData");
  // if (userData) {
  //   const parsedUserData = JSON.parse(userData);
  //   // console.log("🚀 ~ App ~ role:", parsedUserData.role);
  // } else {
  //   console.log("No user data found in localStorage.");
  // }
  
  const token = localStorage.getItem("token");
  useEffect(() => {
    const userData = localStorage.getItem("userData");
     const parsedUserData = JSON.parse(userData);
    if (userData && parsedUserData?.role === 'Admin') {
      dispatch({ type: SIGNIN, payload: JSON.parse(userData) });
    } else {
      navigate("/Login"); // Redirect to login if no user data is found
    }
  }, [dispatch, navigate]);

  if (!userData && !token) {
    return <Login />;
  }

  const parsedUserData = JSON.parse(userData);

  if(parsedUserData?.role !== 'Admin'){
    return <Login />
  }
  return (
    <div className="App">
      <Sidebar />
      <div className="main-content">
        <Header />
        <div className="content">
          <Routes>
            {/* <Route path="/Dashboard" element={<Dashboard />} /> */}
            <Route path="/UserManagement" element={<UserManagement />} />
            <Route path="/Permission" element={<Permission />} />
            <Route
              path="/HekDeviceManagement"
              element={<HekDeviceManagement />}
            />
            <Route path="/LedgerEvent" element={<LedgerEvent />} />
            <Route
              path="/NetworkConnectivity"
              element={<NetworkConnectivity />}
            />
            {/* <Route path="/Support&Documentation" element={<Support />} /> */}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
