import ApiCaller from "../../ApiHeader/ApiCaller";
import VPNConfig from "../Action/VPNConfigAction";

const token = localStorage.getItem("token");
const bearerHeaders = ApiCaller.BearerHeaders(JSON.parse(token));

export default class VPNConfigMiddleware {
  static generate_config(requestData) {
    return async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiCaller.Post(
            "generate_config",
            requestData,
            bearerHeaders
          );
          if (response.status == 201 || response.status == 200) {
            resolve(response.data);
            dispatch(VPNConfig.Generate_Config(response.data));
          }
        } catch (error) {
          alert(error.response.data.error)
        }
      });
    };
  }
}
