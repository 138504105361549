import Axios from "axios";

export const baseUrl = "https://enigmakey.tech/serv/";

export default class ApiCaller {
  static BearerHeaders = (token, More) => {
    return {
      Authorization: "Bearer " + token,
      ...More,
    };
  };

  static async Get(url = "", headers = {}, customUrl = "") {
    try {
      const response = await Axios.get(customUrl ? customUrl : `${baseUrl}${url}`, {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          ...headers,
        },
      });
      return response;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("userData");
        localStorage.removeItem("token");
       setTimeout(() => {
        window.location.reload(); 
       }, 1000);       
      }else if(error?.response?.status === 422){
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      return error.response;
     
    }
  };

  static Post = async (
    endPoint = "",
    body = {},
    headers = {},
    cutomUrl = ""
  ) => {
    return Axios.post(cutomUrl ? cutomUrl : `${baseUrl}${endPoint}`, body, {
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
    });
  };

  static Put = (url = "", body = {}, headers = {}) => {
    return Axios.put(`${baseUrl}${url}`, body, {
      headers: { "Content-Type": "application/json", ...headers },
    })
      .then((res) => res)
      .catch((err) => err.response);
  };

  static Delete = (url = "", body = {}, headers = {}) => {
    return Axios.delete(`${baseUrl}${url}`, {
      headers: { "Content-Type": "application/json", ...headers },
      data: body,
    })
      .then((res) => res)
      .catch((err) => err.response);
  };
  static Patch = async (
    endPoint = "",
    body = {},
    headers = {},
    cutomUrl = ""
  ) => {
    return Axios.patch(cutomUrl ? cutomUrl : `${baseUrl}${endPoint}`, body, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        ...headers,
      },
    });
  };
  static multipart = async (
    endPoint = "",
    body = {},
    headers = {},
    cutomUrl = ""
  ) => {
    return Axios.post(cutomUrl ? cutomUrl : `${baseUrl}${endPoint}`, body, {
      headers: {
        "Content-Type": "application/octet-stream",
        ...headers,
      },
    });
  };
}
