import React, { useEffect, useState } from "react";
import HekTable from "../../Component/HekUserTable/HekUserTable";
import UserDetailCard from "../../Component/UserDetailCard/UserDetailCard";
import { useDispatch, useSelector } from "react-redux";
import AuthMiddleware from "../../Redux/Middleware/AuthMiddleware";
import HekUserModal from "../../Component/Modal/HekAssociateModal";
import AddUserModal from "../../Component/Modal/AddUserModal";
import EditUserModal from "../../Component/EditUserModal";
import axios from "axios";

const UserManagement = () => {
  const { allUsers } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [editUser, setEditUser] = useState(null);

  const token = localStorage.getItem("userData");

  const handleShow = () => setShow(true);

  const handleEdit = (user) => {
    setEditUser(user);
  };


const handleDelete = async (userId) => {
  const token2 = localStorage.getItem("token");
  
  if (!token2) {
    console.error("No token found in local storage.");
    return;
  }
  
  
  try {
    const response = await axios.delete(`https://enigmakey.tech/serv/delete-user/${userId}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token2)}`
      }
    });
    
    
    if (response.status === 200) {
      // Handle successful deletion (e.g., update state, notify user)
      alert('User Deleted Successfully.')
      dispatch(AuthMiddleware.getAllUsers());
    } else {
      console.warn("Unexpected response status:", response.status);
    }
  } catch (error) {
    console.error("🚀 ~ handleDelete ~ error:", error.response ? error.response.data : error.message);
  }
};



  useEffect(() => {
    dispatch(AuthMiddleware.getAllUsers());
  }, [token, dispatch]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <HekTable data={allUsers.users} onShow={handleShow} onDelete={handleDelete} onEdit={handleEdit} />
      {/* <UserDetailCard /> */}
      <AddUserModal show={show} setShow={setShow} />
      {editUser && (
        <EditUserModal
          show={!!editUser}
          handleClose={() => setEditUser(null)}
          user={editUser}
        />
      )}
    </div>
  );
};

export default UserManagement;
