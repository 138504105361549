import { combineReducers } from "redux";

import AuthReducer from "./AuthReducer";
import HekReducer from "./HEKReducer";
import LedgerReducer from "./LedgerEntriesReducer";
import VPNConfigReducer from "./VPNConfigReducer";

export default combineReducers({
  auth: AuthReducer,
  heks: HekReducer,
  ledger: LedgerReducer,
  vpn: VPNConfigReducer,
});
