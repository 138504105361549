import React from "react";
import Permissions from "../../Component/Permissions/Permission";

const Permission = () => {
  return (
    <div>
      <Permissions />
    </div>
  );
};

export default Permission;
