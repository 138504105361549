import React from "react";
import "./UserDetailCard.css";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import UserPro from "../../assets/user.png";
import Cross from '../../assets/cross.png';

const UserDetailCard = ({ CardHeading, showSelectedUserData, showSelectedHEKData,setShowSelectedUserData, setShowSelectedHEKData }) => {


  const filteredUserData = showSelectedUserData
    ? Object.entries(showSelectedUserData).filter(
        ([key]) => !["first_name", "id", "last_name", "password", "role", "username"].includes(key)
      )
    : [];

  const center = {
    lat: 40.732128,
    lng: -73.999619,
  };

  const setShowSelectedUserDataFun = () =>{
    setShowSelectedUserData(null)
  };

  const setShowSelectedHEKDataFun = () => {
    setShowSelectedHEKData(null)
  }

  return (
    <div className="main-container">
      <div className="header" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <span className="card-heading">{CardHeading}</span>
        {CardHeading === 'User Details' ? 
         <button onClick={setShowSelectedUserDataFun}>
         <img src={Cross} width={20} height={20} />
         </button>
         :
         <button onClick={setShowSelectedHEKDataFun}>
         <img src={Cross} width={20} height={20} />
         </button>
      }
       
           {/* <div className="link-tabs">
          <span className="links">Details</span>
          <span className="links">Status</span>
          <span className="links">Monitoring</span>
          <span className="links">Security</span>
          <span className="links">Networking</span>
          <span className="links">Storage</span>
          <span className="links">Tags</span>
        </div> */}
      </div>
      <div className="card-container">
        {CardHeading === 'User Details' ? (
          <div className="user-information">
            {filteredUserData
    .filter(
      ([key]) =>
        !['first login', 'initial setting', 'website', 'profile image'].includes(
          key.toLowerCase().replace(/_/g, ' ')
        )
    )
    .map(([key, value], index) => (
      <div className="user-detail" key={index}>
        <span className="detail-heading">{key.replace(/_/g, ' ')}</span>
        <p className="detail-value">{value}</p>
      </div>
    ))}
          </div>
        ) : (
          <div className="user-information">
            {showSelectedHEKData &&
              Object.entries(showSelectedHEKData).map(([key, value], index) => (
                <div className="user-detail" key={index}>
                  <span className="detail-heading">{key.replace("_", " ")}</span>
                  <p className="detail-value">{value}</p>
                </div>
              ))}
          </div>
        )}

        {CardHeading === 'User Details' && (
          <div className="user-profile">
            <img src={UserPro} alt="User Profile" width={150} height={150} />
            <div>
              <div className="user-detail">
                <span className="detail-heading">User_ID</span>
                <p className="detail-value">
                  {showSelectedUserData?.id?.substring(0, 18)}
                </p>
              </div>
              <div className="user-detail">
                <span className="detail-heading">Name</span>
                <p className="detail-value">{showSelectedUserData?.first_name} {showSelectedUserData?.last_name}</p>
              </div>
              <div className="user-detail">
                <span className="detail-heading">Username</span>
                <p className="detail-value">{showSelectedUserData?.username}</p>
              </div>
              <div className="user-detail">
                <span className="detail-heading">Role</span>
                <p className="detail-value">{showSelectedUserData?.role}</p>
              </div>
            </div>
          </div>
        )}

         {/* <div className="user-location">
          <span className="detail-heading">HEK Location</span>
          <LoadScript googleMapsApiKey="YOUR_API_KEY">
            <GoogleMap
              mapContainerClassName="map-container"
              center={center}
              zoom={15}
            >
              <Marker position={center} />
            </GoogleMap>
          </LoadScript>
          <span className="detail-value">
            277 Bedford Ave, Brooklyn, NY 11211, USA
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default UserDetailCard;
