import { All_HEKS, AVAILABLE_HEKS, All_HEKS_WITH_IP } from "../action_type";

export default class HekActions {
  static getAllHeks = (data) => {
    return {
      type: All_HEKS,
      payload: data,
    };
  };
  static getAvailableHeks = (data) => {
    return {
      type: AVAILABLE_HEKS,
      payload: data,
    };
  };
  static getHekWithIP = (data) => {
    return {
      type: All_HEKS_WITH_IP,
      payload: data
    }
  }
}
