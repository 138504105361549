import React, { useState, useEffect, useCallback } from "react";
import "./HekUserTable.css";
import Refresh from "../../assets/refresh.png";
import CSV from "../../assets/csv.png";
import Editing from "../../assets/Editing.png";
import Bin from "../../assets/Bin.png";
import Search from "../../assets/search.png";
import NoDataFound from '../../assets/data_not_found.png'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CSVLink } from "react-csv";
import debounce from 'lodash.debounce';
import UserDetailCard from "../UserDetailCard/UserDetailCard";
import { Oval } from 'react-loader-spinner';

function HekTable({ data, onShow, onEdit, onDelete }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [showSelectedUserData, setShowSelectedUserData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);

}, [filteredData]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const windowRefresh = () => {
    window.location.reload();
  };

  const selectUser = (user) => {
    setShowSelectedUserData(user)
  }

  const handleEditClick = (user) => {
    onEdit(user);
    setShowModal(true);
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setShow(true);
  };

  const handleDeleteConfirm = () => {
    onDelete(selectedUser.id);
    setShow(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const headers = [
    { label: "User ID", key: "id" },
    { label: "User Name", key: "username" },
    { label: "Email", key: "email" },
    { label: "Serial Number", key: "Serial_Number" },
    { label: "Client IP", key: "client_ip" },
    { label: "Client Public Key", key: "client_public_key" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Phone Number", key: "phone_number" },
    { label: "User Role", key: "role" }
  ];



  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query) {
        const lowerCaseQuery = query.toLowerCase();
        const filtered = data?.filter((item) =>
          Object.values(item).some(
            (val) =>
              val &&
              val.toString().toLowerCase().includes(lowerCaseQuery)
          )
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(data);
      }
    }, 300),
    [data]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch]);

  return (
    <div className="tableContainer">
      <div className="headerContainer">
        <div className="headerTitle">
          <h2 style={{ fontWeight: "bolder" }}>Hek Users</h2>
        </div>
        <div className="headerTitle">
          <button className="iconbutton" onClick={windowRefresh}>
            <img src={Refresh} width={40} height={35} alt="reload-icon" />
          </button>
          {data?.length > 0 && (
            <CSVLink
              data={data}
              headers={headers}
              filename={"users-data.csv"}
              style={{ fontSize: 12, color: 'gray' }}
              onClick={event => {
                alert('CSV is downloading...');
              }}
            >
              <img src={CSV} width={30} alt="csv-icon" />
            </CSVLink>
          )}
        </div>
      </div>
      {filteredData === undefined && !filteredData ? 
      <> 
      {isLoading ? (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', margin: '100px auto' }}>
         <Oval
           visible={true}
           height="80"
           width="80"
           color="black"
           secondaryColor="red"
           ariaLabel="oval-loading"
           wrapperStyle={{}}
           wrapperClass=""
         />
         </div>
      ) : (
       <>
          <div style={{display: 'flex', justifyContent: 'center'}}> 
      <img src={NoDataFound} alt="no_data_found"  />
      </div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}> 
        <h5> No data found try to reload the browser </h5> 
        <button className="iconbutton" onClick={windowRefresh}>
            <img src={Refresh} width={40} height={35} alt="reload-icon" />
          </button>
      </div>
       </>
      )}
   
      </>
      : 
      <>
       <div className="filterContainer">
        <div className="headerTitle">
          <div className="search-box">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
            <button className="search-icon">
              <img src={Search} width={18} height={16} alt="search-icon" />
            </button>
          </div>
        </div>
        <div>
          <button className="add-button" onClick={onShow}>Add User</button>
        </div>
      </div>
      <div className="user-table" style={{maxHeight: showSelectedUserData ? '40vh' : '100vh'}}>
        <table style={{ width: "100%", margin: "13px" }}>
          <thead>
            <tr>
              {/* <th></th> */}
              <th>ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Role</th>
              <th>Serial Number</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((item) => (
              <tr key={item?.id}>
                {/* <td>
                <button className="iconbutton" onClick={() => selectUser(item)}>
                  <input type="radio" name="select" />
                  </button>
                </td> */}
                <td>   <button className="iconbutton" onClick={() => selectUser(item)}>
                  <input type="radio" name="select" />
                  </button> {item?.id.substring(0, 10)}...</td>
                <td>{item?.first_name}</td>
                <td>{item?.last_name}</td>
                <td>{item?.username}</td>
                <td>{item?.email}</td>
                <td>{item?.role}</td>
                <td>{item?.Serial_Number ? item?.Serial_Number : "-"}</td>
                <td>
                  <button className="iconbutton" onClick={() => handleEditClick(item)}>
                    <img src={Editing} alt="Edit"  />
                  </button>
                  <button className="iconbutton" onClick={() => handleDeleteClick(item)}>
                    <img src={Bin} alt="Delete" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showSelectedUserData ? <UserDetailCard showSelectedUserData={showSelectedUserData} setShowSelectedUserData={setShowSelectedUserData} CardHeading={"User Details"} /> : null}
      
      </>
      }
     
      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>Do you want to delete {selectedUser?.first_name} {selectedUser?.last_name}?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteConfirm}>
              Delete
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default HekTable;
