import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Modal.css";
import tick from "../../assets/Tick.png";
import { useDispatch } from "react-redux";
import AuthMiddleware from "../../Redux/Middleware/AuthMiddleware";
import HeksMiddleware from "../../Redux/Middleware/HekMiddleware";
import ApiCaller from "../../ApiHeader/ApiCaller";

const AddHekModal = ({ show, setShow }) => {
  const [Serial_Number, setSerial_Number] = useState();

  const handleClose = () => setShow(false);
  const token = localStorage.getItem("token");
  const bearerHeaders = ApiCaller.BearerHeaders(JSON.parse(token));

  const dispatch = useDispatch();

  const AddHek = async () => {
    try {
      const response = await ApiCaller.Post(
        "add-hek",
        {
          Serial_Number,
        },
        bearerHeaders
      );
      alert(response.data.message);
      dispatch(HeksMiddleware.getHeks());
      dispatch(AuthMiddleware.getAllUsers());
      handleClose()
    } catch (error) {
      console.error(error);
      alert(error?.response?.data?.error)
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="modal-container">
          <Modal.Title>Add HEKs</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-container">
          <label className="modal-input-label">
            Serial Number
            <input
              type="text"
              className="modal-input"
              value={Serial_Number}
              onChange={(e) => setSerial_Number(e.target.value)}
            />
          </label>
        </Modal.Body>
        <Modal.Footer className="modal-container">
          <Button className="modal-button d-flex" onClick={AddHek}>
            <img src={tick} width={15} height={15} />
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddHekModal;
