import { VPN_CONFIG_GENERATE } from "../action_type";

const initialState = {
  generate_vpn: [],
};

const VPNConfigReducer = (state= initialState, action) => {
  switch (action.type) {
    case VPN_CONFIG_GENERATE:
      state = { ...state, generate_vpn: action.payload };

      break;

    default:
      break;
  }

  return state;
};


export default VPNConfigReducer