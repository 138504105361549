import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Modal.css";
import tick from "../../assets/Tick.png";
import ApiCaller from "../../ApiHeader/ApiCaller";

const HekUserModal = ({ show, setShow, hek, user }) => {
  const handleClose = () => setShow(false);
  const [hekSerialNumber, sethekSerialNumber] = useState("");
  const [Username, setUsername] = useState("");
  const userId = user?.users?.find((el) => el.username === Username)?.id;
  const token = localStorage.getItem("token");
  const bearerHeaders = ApiCaller.BearerHeaders(JSON.parse(token));

  const onSubmit = async () => {
    try {
      const response = await ApiCaller.Post(
        "associate-hek-with-user",
        {
          userId,
          hekSerialNumber,
        },
        bearerHeaders
      );
      alert(response.data.message)
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.error)
    }
    handleClose()
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="modal-container">
          <Modal.Title>Associate HEK to User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-container">
          <label className="modal-input-label">
            Serial Number
            <select
              value={hekSerialNumber}
              onChange={(e) => sethekSerialNumber(e.target.value)}
              className="modal-input"
            >
              <option value="">Select...</option>
              {hek?.heks?.map((el, index) => (
                <option value={el.Serial_Number} key={index}>{el.Serial_Number}</option>
              ))}
            </select>
          </label>
          <label className="modal-input-label">
            Username
            <select
              value={Username}
              onChange={(e) => setUsername(e.target.value)}
              className="modal-input"
            >
              <option>Select...</option>
              {user?.users?.map((el, index) => (
                <option key={index}>{el.username}</option>
              ))}
            </select>
          </label>
        </Modal.Body>
        <Modal.Footer className="modal-container">
          <Button className="modal-button d-flex" onClick={onSubmit}>
            <img src={tick} width={15} height={15} />
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HekUserModal;
