import { SIGNIN, All_USERS, All_USERS_WITH_IP, ADD_USERS, EDIT_USER, DELETE_USER } from "../action_type";

export default class AuthAction {
  static Signin = (data) => {
    
    return {
      type: SIGNIN,
      payload: data,
    };
  };
  static allUsers = (data) => {
    return {
      type: All_USERS,
      payload: data,
    };
  };
  static allUsersWithIp = (data) => {
    return {
      type: All_USERS_WITH_IP,
      payload: data,
    };
  };
  static addUser = (data) => {
    return {
      type: ADD_USERS,
      payload: data,
    };
  };
  
  static editUser = (userData) => {
    return {
      type: EDIT_USER,
      payload: userData,
    };
  };

  static deleteUser = (userId) => {
    return {
      type: DELETE_USER,
      payload: userId,
    };
  };
  static logout = () => {
    return {
      type: "LOGOUT",
    };
  };
}
