import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from "react-redux";
import AuthMiddleware from "../../Redux/Middleware/AuthMiddleware";
import axios from "axios";
import AuthAction from "../../Redux/Action/AuthAction";
import { useNavigate } from "react-router-dom";

const EditUserModal = ({ show, handleClose, user }) => {
  const [userData, setUserData] = useState(user);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Set user data and ensure the password field is empty
    setUserData({
      ...user,
      password: "",
    });
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(`https://enigmakey.tech/serv/update-user/${user.id}`, { ...userData, password: newPassword }, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`
        }
      });
      if(response?.data?.message === 'User updated successfully'){
          handleClose();
          dispatch(AuthMiddleware.getAllUsers());
      }
      // if( response.data?.updated_fields?.role !== 'Admin'){
      //   localStorage.clear(); // Clear user data from local storage
      //   //  localStorage.removeItem("persist:root");
      //   dispatch(AuthAction.logout()); // Dispatch the logout action
      //   navigate("/login"); // Redirect to login page
      // }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          {[
            { name: "Serial_Number", readonly: true },
            { name: "client_public_key", readonly: true },
            { name: "email", readonly: true },
            { name: "first_name", readonly: false },
            { name: "last_name", readonly: false },
            { name: "phone_number", readonly: false },
            { name: "username", readonly: false },
            // { name: "password", readonly: false },
          ].map(({ name, readonly }) => (
            <div key={name} className="form-group" style={{ display: 'flex', flexDirection: 'row', padding: 5, justifyContent: 'space-between', alignItems: 'center' }}>
              <label style={{ fontSize: 14, fontWeight: 'bold' }}>{name.replace(/_/g, ' ')}</label>
              <input
                className="form-control"
                name={name}
                value={userData[name] || ""}
                onChange={handleChange}
                placeholder={name.replace(/_/g, ' ')}
                style={{
                  width: '75%',
                  backgroundColor: readonly ? '#d3d3d3' : 'white',
                }}
                readOnly={readonly}
              />
            </div>
          ))}

          {/* Old Password Input */}
          {/* <div className="form-group" style={{ display: 'flex', flexDirection: 'row', padding: 5, justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ fontSize: 14, fontWeight: 'bold' }}>Old Password</label>
            <input
              type="password"
              className="form-control"
              value={oldPassword}
              onChange={handleOldPasswordChange}
              placeholder="Old Password"
              style={{ width: '75%' }}
            />
          </div> */}
          {/* New Password Input */}
          <div className="form-group" style={{ display: 'flex', flexDirection: 'row', padding: 5, justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ fontSize: 14, fontWeight: 'bold' }}>New Password</label>
            <input
              type="password"
              className="form-control"
              value={newPassword}
              onChange={handlePasswordChange}
              placeholder="New Password"
              style={{ width: '75%' }}
            />
          </div>

          {/* Confirm Password Input */}
          <div className="form-group" style={{ display: 'flex', flexDirection: 'row', padding: 5, justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ fontSize: 14, fontWeight: 'bold' }}>Confirm Password</label>
            <input
              type="password"
              className="form-control"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeholder="Confirm Password"
              style={{ width: '75%' }}
            />
          </div>

          <div className="form-group" style={{ display: 'flex', flexDirection: 'row', padding: 5, justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ fontSize: 14, fontWeight: 'bold' }}>Role</label>
            <select
              className="form-control"
              name="role"
              value={userData.role || ""}
              onChange={handleChange}
              style={{ width: '75%' }}
            >
              <option value="Admin">Admin</option>
              <option value="Sub Admin">Sub Admin</option>
              <option value="user">User</option>
            </select>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUserModal;
