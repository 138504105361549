import React, {useState} from "react";
import "./VPNProfile.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Bin from "../../assets/Bin.png";

const VPNTable = ({ data, TableHeader, onDelete }) => {

  const [selected, setSelected] = useState(null);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const handleDeleteClick = (data) => {
    setSelected(data);
    setShow(true);
  };

  const handleDeleteConfirm = () => {
    onDelete(selected.hek_id || selected.user_id);
    setShow(false);
  };

  const handleClose = () => setShow(false);

  return (
    <div className="tableContainer" style={{height: 'auto'}}>
      <div className="headerContainer">
        <span>{TableHeader}</span>
      </div>
      <div className="tableScrollable">
        <table
          style={{
            width: "100%",
            overflow: "auto",
            backgroundColor: "#FDFDFD",
          }}
        >
          <thead>
            <tr>
              {/* <th></th> */}
              <th>
                {TableHeader === "Manage User VPN Profile"
                  ? "User_ID"
                  : "HEK_ID"}
              </th>
              <th>
                {TableHeader === "Manage User VPN Profile"
                  ? "Username"
                  : "Serial Number"}
              </th>
              <th>IP Address</th>
              <th>Public Key</th>
              {/* <th style={{textAlign: 'center'}}>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {data?.heks
              ? data?.heks.map((item, index) => (
                  <tr key={index}>
                    {/* <td>
                      <input type="checkbox" />
                    </td> */}
                    <td>{item?.hek_id.substring(0, 18)}..</td>
                    <td>{item?.Serial_Number}</td>
                    <td>{item?.ip_address}</td>
                    <td>{item?.public_key}</td>

                    {/* <td>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <button className="iconbutton" onClick={() => handleDeleteClick(item)}>
                        <img src={Bin} alt="Bin" />
                      </button>
                      </div>
                    </td> */}
                  </tr>
                ))
              : data?.users?.map((item, index) => (
                  <tr key={index}>
                    {/* <td>
                      <input type="checkbox" />
                    </td> */}
                    <td>{item?.user_id.substring(0, 18)}..</td>
                    <td>{item?.username}</td>
                    <td>{item?.ip_address}</td>
                    <td>{item?.public_key}</td>

                    {/* <td>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <button className="iconbutton" onClick={() => handleDeleteClick(item)}>
                        <img src={Bin} alt="Bin" />
                      </button>
                      </div>
                    </td> */}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>Do you want to delete {selected?.Serial_Number || selected.username}?</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleDeleteConfirm}>
              Delete
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default VPNTable;
