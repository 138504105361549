import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from "react-redux";
import AuthMiddleware from "../../Redux/Middleware/AuthMiddleware";
import HeksMiddleware from "../../Redux/Middleware/HekMiddleware";
import axios from "axios";

const EditHEKModal = ({ show, handleClose, user }) => {
  const [hekData, setHekData] = useState(user);
  const dispatch = useDispatch();

  useEffect(() => {
    setHekData(user);
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHekData({
      ...hekData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`https://enigmakey.tech/serv/update-hek/${hekData.id}`, hekData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`
        }
      });

      if(response?.data?.message === 'HEK updated successfully'){
          // You can dispatch any actions here if needed
          handleClose();
          dispatch(HeksMiddleware.getHeks());
          dispatch(AuthMiddleware.getAllUsers());
      }
      // await dispatch(AuthMiddleware.editUser(userData.id, userData));
      // handleClose();
    } catch (error) {
      console.error("Error updating user:", error);
      // Handle error (e.g., show an error message to the user)
    }
    // await dispatch(AuthMiddleware.editUser(hekData.id, hekData));
    // handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit HEK</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          {/* Readonly fields with light gray background */}
          {[
            { name: "Serial_Number", readonly: true },
            { name: "client_public_key", readonly: true }
          ].map(({ name, readonly }) => (
            <div key={name} className="form-group" style={{ display: 'flex', flexDirection: 'row', padding: 5, justifyContent: 'space-between', alignItems: 'center' }}>
              <label style={{ fontSize: 14, fontWeight: 'bold' }}>{name.replace(/_/g, ' ')}</label>
              <input
                className="form-control"
                name={name}
                value={hekData[name] || ""}
                onChange={handleChange}
                placeholder={name.replace(/_/g, ' ')}
                style={{
                  width: '75%',
                  backgroundColor: readonly ? '#d3d3d3' : 'white', // Light gray background for readonly fields
                }}
                readOnly={readonly}
              />
            </div>
          ))}

          {/* Dropdown fields */}
          {[
            { name: "Access", options: ["Allowed", "Blocked"] },
            { name: "Status", options: ["Active", "Inactive"] }
          ].map(({ name, options }) => (
            <div key={name} className="form-group" style={{ display: 'flex', flexDirection: 'row', padding: 5, justifyContent: 'space-between', alignItems: 'center' }}>
              <label style={{ fontSize: 14, fontWeight: 'bold' }}>{name}</label>
              <select
                className="form-control"
                name={name}
                value={hekData[name] || ""}
                onChange={handleChange}
                style={{ width: '75%' }}
              >
                {options.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
          ))}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditHEKModal;
