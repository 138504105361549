import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Modal.css";
import tick from "../../assets/Tick.png";
import { useDispatch } from "react-redux";
import AuthMiddleware from "../../Redux/Middleware/AuthMiddleware";
import ApiCaller from "../../ApiHeader/ApiCaller";

const AddUserModal = ({ show, setShow }) => {
  const [first_name, setFirstname] = useState();
  const [last_name, setLastname] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [username, setUsername] = useState();
  const [phone_number, setPhonenumber] = useState();
  const [role, setRole] = useState();
  const handleClose = () => setShow(false);
  const token = localStorage.getItem("token");
  const bearerHeaders = ApiCaller.BearerHeaders(JSON.parse(token));

  const dispatch = useDispatch();

  const AddUser = async () => {
    try {
      const response = await ApiCaller.Post(
        "add-user",
        {
          first_name,
          last_name,
          email,
          password,
          username,
          phone_number,
          role,
        },
        bearerHeaders
      );
      alert(response.data.message);
      if(response?.data?.message === 'User created successfully'){
        dispatch(AuthMiddleware.getAllUsers());
      }
      handleClose();
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="modal-container">
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-container">
          <label className="modal-input-label">
            Firstname
            <input
              type="text"
              className="modal-input"
              value={first_name}
              onChange={(e) => setFirstname(e.target.value)}
            />
          </label>
          <label className="modal-input-label">
            Lastname
            <input
              type="text"
              className="modal-input"
              value={last_name}
              onChange={(e) => setLastname(e.target.value)}
            />
          </label>
          <label className="modal-input-label">
            Username
            <input
              type="text"
              className="modal-input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
          <label className="modal-input-label">
            Email
            <input
              type="email"
              className="modal-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label className="modal-input-label">
            Password
            <input
              type="password"
              className="modal-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <label className="modal-input-label">
            Phone Number
            <input
              type="text"
              className="modal-input"
              value={phone_number}
              onChange={(e) => setPhonenumber(e.target.value)}
            />
          </label>
          <label className="modal-input-label">
            Role
            <select
              className="modal-input"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="">Select...</option>
              <option value="Admin">Admin</option>
              <option value="Sub Admin">Sub Admin</option>
              <option value="user">User</option>
            </select>
          </label>
        </Modal.Body>
        <Modal.Footer className="modal-container">
          <Button className="modal-button d-flex" onClick={AddUser}>
            <img src={tick} width={15} height={15} />
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUserModal;
