import React, { useEffect, useState } from "react";
import HekTable from "../../Component/HekTable/HekTable";
import UserDetailCard from "../../Component/UserDetailCard/UserDetailCard";
import HeksMiddleware from "../../Redux/Middleware/HekMiddleware";
import { useDispatch, useSelector } from "react-redux";
import HekUserModal from "../../Component/Modal/HekAssociateModal";
import AuthMiddleware from "../../Redux/Middleware/AuthMiddleware";
import AddHekModal from "../../Component/Modal/AddNewHek";
import EditHEKModal from "../../Component/EditHEKModal";
import axios from "axios";

const HekDeviceManagement = () => {
  const { Heks, availableHeks } = useSelector((state) => state.heks);
  const { allUsers } = useSelector((state) => state.auth);

  const [show, setShow] = useState(false);
  const [showHek, setShowHek] = useState(false);
  const handleShow = () => setShow(true);
  const handleHekModal = () => setShowHek(true);
  const dispatch = useDispatch();
  const [editHek, setEditHek] = useState(null);

  const token = localStorage.getItem("userData");

  const handleEdit = (hek) => {
    setEditHek(hek);
  };

  const handleDelete = async (HekId) => {
    const token2 = localStorage.getItem("token");
  
    if (!token2) {
      console.error("No token found in local storage.");
      return;
    }
    // Implement the delete logic here
    try {
      const response = await axios.delete(`https://enigmakey.tech/serv/delete-hek/${HekId}`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token2)}`
        }
      });
      
      
      if (response.status === 200) {
        // Handle successful deletion (e.g., update state, notify user)
        alert('HEK Deleted Successfully.')
        dispatch(HeksMiddleware.getHeks());
    dispatch(AuthMiddleware.getAllUsers());
      } else {
        console.warn("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("🚀 ~ handleDelete ~ error:", error.response ? error.response.data : error.message);
    }
    // Dispatch an action to delete the user
    // dispatch(AuthMiddleware.deleteUser(userId));
  };

  useEffect(() => {
    dispatch(HeksMiddleware.getHeks());
    dispatch(HeksMiddleware.availableHeks());
    dispatch(AuthMiddleware.getAllUsers());
  }, [token]);
  
  return (
    <div>
      <HekTable data={Heks.heks} onShow={handleShow} showHek={handleHekModal} onEdit={handleEdit} onDelete={handleDelete} />
      {/* <UserDetailCard CardHeading={"HEK Details"} /> */}
      <HekUserModal setShow={setShow} show={show} hek={availableHeks} user={allUsers} />
      <AddHekModal setShow={setShowHek} show={showHek} />
      {editHek && (
        <EditHEKModal
          show={!!editHek}
          handleClose={() => setEditHek(null)}
          user={editHek}
        />
      )}
    </div>
  );
};

export default HekDeviceManagement;
