import { LEDGER_ENTRIES } from "../action_type";

const initialState = {
  Ledgers: [],
};

const LedgerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEDGER_ENTRIES:
      state = { ...state, Ledgers: action.payload };
      break;

    default:
      break;
  }

  return state;
};


export default LedgerReducer;
