import {
  ADD_USERS,
  All_USERS,
  All_USERS_WITH_IP,
  LOGOUT,
  SIGNIN,
  EDIT_USER,
  DELETE_USER
} from "../action_type";

const initialState = {
  allUsers: [],
  user: null,
  userWithIps: [],
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN:
      state = { ...state, user: action.payload };
      break;

    case All_USERS:
      state = { ...state, allUsers: action.payload };
      break;
    case All_USERS_WITH_IP:
      state = { ...state, userWithIps: action.payload };
      break;
      case EDIT_USER:
        return {
          ...state,
          users: state.allUsers.map(user => 
            user.id === action.payload.id ? action.payload : user
          ),
        };
      case DELETE_USER:
        return {
          ...state,
          allUsers: state.allUsers.filter(user => user.id !== action.payload),
        };
        case LOGOUT:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
      // break;
  }

  return state;
};

export default AuthReducer;
