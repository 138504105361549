import { All_HEKS, AVAILABLE_HEKS, All_HEKS_WITH_IP } from "../action_type";

const initialState = {
  Heks: [],
  availableHeks: [],
  HekwithIps: [],
};
const HekReducer = (state = initialState, action) => {
  // console.log("🚀 ~ HekReducer ~ action:", action)
  switch (action.type) {
    case All_HEKS:
      state = { ...state, Heks: action.payload };
      break;
    case AVAILABLE_HEKS:
      state = { ...state, availableHeks: action.payload };
      break;
    case All_HEKS_WITH_IP:
      state = { ...state, HekwithIps: action.payload };
      break;
    default:
      break;
  }
  return state;
};

export default HekReducer;
