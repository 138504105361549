import React, { useState } from "react";
import "./Permission.css";

const permissionsData = [
  {
    permission: "HEK Management",
    description:
      "Issue and manage HEXs, set statuses, and possibly initiate processes that involve HEXs, like generating configurations or associating HEXs with specific users.",
    status: "Enabled",
  },
  {
    permission: "Manage Users",
    description:
      "Ability to view, manage, and update user accounts, but perhaps not delete them. This might include resetting passwords, updating user roles without the ability to delete user profiles.",
    status: "Disabled",
  },
  {
    permission: "Monitoring and Logs",
    description:
      "Access to view system logs, user activity, and other operational data for monitoring purposes, but without the ability to delete logs.",
    status: "Disabled",
  },
  {
    permission: "Support and Troubleshooting",
    description:
      "Ability to access support tools, respond to help requests, and perform diagnostic actions to resolve user issues.",
    status: "Enabled",
  },
  {
    permission: "Access Control",
    description:
      "Implement and manage access control for users, including permissions to access certain network resources, without having full control over network-wide security settings.",
    status: "Disabled",
  },
  {
    permission: "Dashboard and Reports",
    description:
      "Access to a dashboard that provides an overview of system status, user activities, and other relevant reports, with the ability to drill down for more detailed information.",
    status: "Enabled",
  },
  {
    permission: "Service Management",
    description:
      "Start or stop certain micro-services, schedule routine tasks, and manage service-related configurations to ensure smooth operation.",
    status: "Enabled",
  },
  {
    permission: "VPN Configuration",
    description:
      "Modify VPN configurations for users, manage connection settings, and possibly initiate or terminate user VPN sessions.",
    status: "Disabled",
  },
];
const Permissions = () => {
  const [isOn, setIsOn] = useState(false);

  const handleToggle = () => {
    setIsOn(!isOn);
  };
  return (
    <div className="permission-container">
      <span className="main-heading">Permission to Sub-Admins</span>
      {permissionsData.map((el, index

      ) => (
        <div className="permissions" key={index}>
          <div className="permission-detail">
            <span className="detail-heading">{el.permission}</span>
            <span className="detail-desc">{el.description}</span>
          </div>
          <div
            className={`toggle-switch ${isOn ? "on" : "off"}`}
            onClick={handleToggle}
          >
            <div className="toggle-thumb"></div>
          </div>
        </div>
      ))}
      <div className="button-container">
        <button className="permission-button">
          Save Change
        </button>
      </div>
    </div>
  );
};

export default Permissions;
