
import { LEDGER_ENTRIES } from "../action_type";

export default class LegderAction {
  static AllLedgerEntries(data) {
    return {
      type: LEDGER_ENTRIES,
      payload: data,
    };
  }
}
