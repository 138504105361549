import ApiCaller from "../../ApiHeader/ApiCaller";
import LegderAction from "../Action/LedgerAction";

const token = localStorage.getItem("token");
const bearerHeaders = ApiCaller.BearerHeaders(JSON.parse(token));

export default class LedgerMiddleware {
  static getLedgerEntries() {
    return async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await ApiCaller.Get(
            "ledger/all-entries",
            bearerHeaders
          );
          if (response.status == 201 || response.status == 200) {
            resolve(response.data);
            dispatch(LegderAction.AllLedgerEntries(response.data));
          } 
        } catch (error) {
          console.error('Error fetching Heks:', error);
        }
      });
    };
  }
}
