import React, { useEffect, useState } from "react";
import "./Header.css";
import Logo from "../../assets/Arrowcircle.png";
import logo2 from "../../assets/Notification.png";
import Logout from '../../assets/logout.png';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthAction from "../../Redux/Action/AuthAction";
import moment from "moment";

function Header() {
  const { Ledgers } = useSelector((state) => state.ledger);
  const [data, setData] = useState([Ledgers.ledger_entries])
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear(); // Clear user data from local storage
    //  localStorage.removeItem("persist:root");
    dispatch(AuthAction.logout()); // Dispatch the logout action
    navigate("/login"); // Redirect to login page
  };
  const formatTimestamp = (timestamp) => {
    return moment(timestamp).format('DD-MM-YYYY hh:mm:ss A');
  };

  return (
    <div className="App-header">
      <div className="update-section">
  {data?.map((el, index) => {
    return (
      <span key={index}>Last Updated {formatTimestamp(el?.timestamp) }</span>
    );
  })}

  <button>
    <img src={Logo} width={15} height={15} />
  </button>
</div>
      <div className="user-info">
        <button onClick={handleLogout}>
          <img src={Logout} width={20} height={20} />
        </button>
        <button onClick={handleLogout} style={{marginLeft: -12}}> Logout</button>
      </div>
    </div>
  );
}

export default Header;
